class ToggleSubMenuManager {
    constructor(parent, data) {
    this.parent = parent
    this.data = data
    this.elements = []
    }
    run() {
    document.querySelectorAll('[data-toggle-sub-menu]').forEach((el) => {
    const mToggleSubMenu = new ToggleSubMenu(el, this)
    mToggleSubMenu.run()
    this.elements.push(mToggleSubMenu)
    })
    window.addEventListener('resize', () => {
    if (window.matchMedia('(min-width: 600px)').matches) {
    this.closeAll()
    }
    })
    }
    closeAll() {
    this.elements.forEach(mToggleSubMenu => mToggleSubMenu.closeAction())
    }
}

class ToggleSubMenu {
    constructor(el, parent) {
    this.el = el
    this.parent = parent
    this.id = this.el.dataset.toggleSubMenu
    this.classOpen = this.el.dataset.toggleSubMenuClass
    }
    run() {
    let querySelect = '[data-toggle-sub-menu-action]'
    if (this.id) {
    querySelect = '[data-toggle-sub-menu-action="' + this.id + '"]'
    }
    document.querySelectorAll(querySelect).forEach((elAction) => this.initAction(elAction))

    }
    initAction(elAction) {
    elAction.addEventListener('click', (evt) => {
    evt.preventDefault()
    this.openAction()
  
    })
    }
    openAction() {
    this.parent.closeAll()
    this.el.classList.add(this.classOpen)
    }
    toggleAction() {
    this.el.classList.toggle(this.classOpen)
    }
    closeAction() {

    this.el.classList.remove(this.classOpen)
    }
}

export default ToggleSubMenuManager