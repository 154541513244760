class SwiperManager {
    constructor(parent, data) {
    this.parent = parent
    this.data = data
    }
    run() {
  

    }
}

export default SwiperManager