// import ComponentA from './Components/ComponentA'
import ToggleSubMenuManager from "./Components/ToggleSubMenuManager"
import SwiperManager from "./Components/SwiperManager"
import Menu from "./Components/Menu"
import Scroll from "./Components/Scroll"
import Popup from "./Components/Popup"

document.addEventListener('DOMContentLoaded', () => {
    let root = {}
    root.toggleSubMenuManager = new ToggleSubMenuManager(root, [])
    root.toggleSubMenuManager.run();
    root.swiperManager = new SwiperManager(root, [])
    root.swiperManager.run()
    root.menu = new Menu(root, [])
    root.menu.run()
    root.scroll = new Scroll(root, [])
    root.scroll.run()
    root.popup = new Popup(root, [])
    root.popup.run()

}, false)
