class Scroll {
    constructor(parent, data) {
    this.parent = parent
    this.data = data
    }
    run() {
    this.el = document.querySelector('[data-header]')
    if(!this.el) {
    return
    }
    window.addEventListener('scroll', () => this.onScroll())
    this.onScroll()
    }
    onScroll() {
    if(window.scrollY > 0) {
    this.el.classList.add("header--is-fixed")
    } else {
    this.el.classList.remove("header--is-fixed")
    }
    }
}

export default Scroll