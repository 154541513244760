class Popup {
    constructor(parent, data) {
    this.parent = parent
    this.data = data
    this.classOpen = 'popup--is-show'
    }
    run() {
    document.querySelectorAll('[data-popup]').forEach((el) => this.init(el))
    }
    init(elParent) {
    const elID = elMenu.dataset.popup;
    document.querySelectorAll('[data-popup-toggle="' + elID + '"]').forEach((el) => this.initAction(el, elParent, 'toggle'))
    document.querySelectorAll('[data-popup-open="' + elID + '"]').forEach((el) => this.initAction(el, elParent, 'open'))
    document.querySelectorAll('[data-popup-close="' + elID + '"]').forEach((el) => this.initAction(el, elParent, 'close'))
    }
    initAction(elAction, elParent, actionType) {
    elAction.addEventListener('click', () => {
    if ('open' === actionType) {
    elParent.classList.add(this.classOpen)
    } else if ('close' === actionType) {
    elParent.classList.remove(this.classOpen)
    } else {
    elParent.classList.toggle(this.classOpen)
    }
    })
    }
}

export default Popup